<template>
  <div>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="addIntroBannerDialog" @click:outside="closeIntroAddBannerDialog" max-width="1200px" :retain-focus="false" eager>
      <v-card min-height="850px">
        <v-icon
            style="top: 10px; left: 97%; position:sticky;"
            class="close__modal"
            @click="closeIntroAddBannerDialog"
        >mdi-close</v-icon
        >
        <v-card-title>
          <span style="text-transform:capitalize;" class="text-h5">{{ type }} intro banner</span>
        </v-card-title>
        <v-card-text style="display:flex;min-height:800px;">
          <v-tabs v-model="addBannerDialogTabs">
            <v-tab>Styles</v-tab>
            <v-tab>Period</v-tab>
            <v-tab>Segments</v-tab>
            <v-tab-item>
              <div style="display: flex;padding:15px;">
                <div style="display: flex;align-items: center;">
                  <label class="label" style="display: block;width:200px;margin-bottom:0;margin-right:8px;">Name</label>
                  <v-text-field
                      outlined
                      dense
                      style="width:250px;"
                      hide-details
                      v-model="addIntroBannerModel.name"
                  ></v-text-field>
                </div>
              </div>
              <div style="display: flex;padding:15px;">
                <div style="display: flex;align-items: center;">
                  <label class="label" style="display: block;width:200px;margin-bottom:0;margin-right:8px;">Type</label>
                  <v-select
                      flat
                      dense
                      outlined
                      style="width:250px;"
                      hide-details
                      :items="bannerTypes"
                      item-text="label"
                      item-value="value"
                      class="canvas__select"
                      v-model="addIntroBannerModel.type"
                  >
                    <template v-slot:selection="{ item }">
                      <div>
                        {{ item.label }}
                      </div>
                    </template>
                    <template v-slot:item="{ item }">
                      <div>
                        {{ item.label }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <v-tabs v-model="selected_lang">
                <v-tab v-for="(selected_lang, i) in selected_langs" :key="i" @click="changeLang(selected_lang)">
                  <img
                      :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                      alt=""
                      style="width: 20px; height: 15px; margin-right: 5px"
                  />
                  {{ selected_lang }}
                </v-tab>
                <v-tab-item v-for="lang in selected_langs" :key="lang">
                </v-tab-item>
              </v-tabs>
                <div style="padding:15px;" v-if="addIntroBannerModel.type === 0">
                  <div class="mb-4">
                    <label class="label" style="width:150px;display:block;margin-bottom:15px;">Image</label>
                    <div>
                      <vue-dropzone
                          id="uploadType0"
                          ref="uploadType0"
                          :options="dropzoneOptions"
                          :destroyDropzone="false"
                          @vdropzone-mounted="mountType0"
                          @vdropzone-sending='customEvent'
                          @vdropzone-removed-file="handleRemove"
                          @vdropzone-success="handleSuccess"
                      >
                      </vue-dropzone>
                    </div>
                  </div>
                </div>
                <div style="padding:15px;" v-if="addIntroBannerModel.type === 1">
                  <div style="display:flex;align-items:center;margin-bottom:15px;">
                    <label class="label" style="margin-bottom:0;width:200px;margin-right:8px;">Upload video</label>
                    <v-file-input
                        accept="video/*"
                        show-size
                        style="max-width:250px;"
                        ref="videoUpload"
                        prepend-icon=""
                        outlined
                        hide-details
                        dense
                        @change="onFileChange"
                    ></v-file-input></div>
                </div>
                <div style="padding:15px;" v-if="addIntroBannerModel.type === 2">
                  <div class="mb-4">
                    <label class="label" style="width:150px;display:block;margin-bottom:15px;">Image</label>
                    <div>
                      <vue-dropzone
                          id="uploadTopPro"
                          ref="uploadTopPro"
                          :options="dropzoneOptionsTopPro"
                          :destroyDropzone="false"
                          @vdropzone-mounted="mountTopPro"
                          @vdropzone-sending='customEvent'
                          @vdropzone-removed-file="handleRemoveTopPro"
                          @vdropzone-success="handleSuccessTopPro"
                      >
                      </vue-dropzone>
                    </div>
                  </div>
                  <div class="mb-4">
                    <label class="label" style="width:150px;display:block;margin-bottom:15px;">Logo Image</label>
                    <div>
                      <vue-dropzone
                          id="uploadTopLogo"
                          ref="uploadTopLogo"
                          :options="dropzoneOptionsTopLogo"
                          :destroyDropzone="false"
                          @vdropzone-mounted="mountTopLogo"
                          @vdropzone-sending='customEvent'
                          @vdropzone-removed-file="handleRemoveTopLogo"
                          @vdropzone-success="handleSuccessTopLogo"
                      >
                      </vue-dropzone>
                    </div>
                  </div>
                  <div class="mb-4">
                    <div>
                      <label class="label" style="width:200px;margin-right:8px;margin-bottom:15px;display:block;">Write your intro text</label>
                      <Editor
                          api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                          :init="options"
                          v-model="addIntroBannerModel.pro[landing.current_lang].main_description">
                      </Editor>
                    </div>
                  </div>
                  <div class="mb-4">
                    <div>
                      <label style="font-weight:bold;" class="label">Next Button Settings:</label>
                    </div>
                    <div style="padding:15px;">
                      <div style="display:flex;align-items:center;margin-bottom:15px;">
                        <label class="label" style="display:block;width:200px;margin-right:8px;margin-bottom:0;">Next button</label>
                        <v-select
                            style="max-width: 250px;"
                            flat
                            dense
                            outlined
                            v-model="addIntroBannerModel.pro[landing.current_lang].btn_next"
                            hide-details
                            :items="next_icons"
                            class="canvas__select"
                        >
                          <template v-slot:selection="{ item }">
                            <v-icon>{{ item }}</v-icon>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-icon>{{ item }}</v-icon>
                          </template>
                        </v-select>
                      </div>
                      <label class="label" style="display:block;width:200px;margin-right:8px;margin-bottom:15px;">Write your next text</label>
                      <Editor
                          api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                          :init="options"
                          v-model="addIntroBannerModel.pro[landing.current_lang].text_next">
                      </Editor>
                    </div>
                  </div>
                  <div class="mb-4">
                    <div>
                      <label style="font-weight:bold;" class="label">Logo Settings:</label>
                    </div>
                    <div style="padding:15px;">
                      <div style="display:flex;align-items:center;margin-bottom:15px;">
                        <label class="label" style="min-width:200px;margin-bottom:0;">Height <b>{{addIntroBannerModel.pro.logo_height}} px</b></label>
                        <div class="control" style="width:100%">
                          <div class="slider">
                            <v-slider
                                hide-details
                                :min="30"
                                :max="500"
                                v-model="addIntroBannerModel.pro.logo_height"
                            ></v-slider>
                          </div>
                        </div>
                      </div>
                      <div style="display:flex;align-items:center;margin-bottom:15px;">
                        <label class="label" style="min-width:200px;margin-bottom:0;">Width <b>{{addIntroBannerModel.pro.logo_width}} px</b></label>
                        <div class="control" style="width:100%">
                          <div class="slider">
                            <v-slider
                                hide-details
                                :min="30"
                                :max="500"
                                v-model="addIntroBannerModel.pro.logo_width"
                            ></v-slider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              <div style="padding:15px;">
                <div>
                  <label style="font-weight:bold" class="label">General Settings:</label>
                  <div style="padding:0 15px;">
                    <div class="mb-4" style="display:flex;align-items:center;justify-content:space-between;">
                      <div style="display:flex;align-items:center;margin-right:15px;">
                        <label class="label" style="margin-bottom:0;width:200px;margin-right:8px;">Show seconds</label>
                        <v-text-field outlined hide-details dense style="max-width:250px;" v-model.number="addIntroBannerModel.show_time" type="number"></v-text-field>
                      </div>
                      <div style="display:flex;align-items:center;">
                        <label class="label" style="margin-bottom:0;margin-right:15px;">Repeat video?</label>
                        <v-switch color="#6ab64a" outlined :value="addIntroBannerModel.repeat?1:0" @change="changeRepeat" dense style="max-width:150px;margin-top:0;padding-top:0;" hide-details ></v-switch>
                      </div>
                    </div>
                    <div class="mb-4" style="display:flex;align-items:center;justify-content: space-between;">
                      <div style="display: flex;align-items: center;">
                        <label class="label" style="margin-bottom: 0;width:200px;margin-right:8px;">Background color</label>
                        <v-menu
                            v-model="addIntroBannerModelBg"
                            top
                            nudge-bottom="98"
                            nudge-left="16"
                            :close-on-content-click="false"
                            z-index="212"
                        >
                          <template v-slot:activator="{ on }">
                            <div :style="`background-color:${addIntroBannerModel.styles.bg}`" class="color" v-on="on" />
                          </template>
                          <v-card>
                            <v-card-text class="pa-0">
                              <v-color-picker
                                  v-model="addIntroBannerModel.styles.bg"
                                  flat
                              />
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <label class="label" style="font-weight:bold;">Exit Icon Settings:</label>
                  <div style="padding:15px;">
                    <div style="display:flex;align-items:center;margin-bottom:15px;">
                      <label class="label" style="margin-bottom: 0;width:200px;margin-right:8px;">Icon color</label>
                      <v-menu
                          v-model="addIntroBannerModelIconColor"
                          top
                          nudge-bottom="98"
                          nudge-left="16"
                          :close-on-content-click="false"
                          z-index="212"
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="`background-color:${addIntroBannerModel.styles.icon_color}`" class="color" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker
                                v-model="addIntroBannerModel.styles.icon_color"
                                flat
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </div>
                    <div style="display:flex;align-items:center;margin-bottom:15px;">
                      <label class="label" style="margin-bottom: 0;width:200px;margin-right:8px;">Icon</label>
                      <v-select
                          style="max-width: 250px;"
                          flat
                          dense
                          outlined
                          v-model="addIntroBannerModel.styles.icon"
                          hide-details
                          :items="icons"
                          class="canvas__select"
                      >
                        <template v-slot:selection="{ item }">
                          <v-icon>{{ item }}</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-icon>{{ item }}</v-icon>
                        </template>
                      </v-select>
                    </div>
                    <div style="display:flex;align-items:center;margin-bottom:15px;">
                      <label class="label" style="margin-bottom: 0;min-width:200px">Icon size</label>
                      <div style="width:100%;">
                        <v-slider
                            :max="50"
                            hide-details
                            :min="20"
                            v-model="addIntroBannerModel.styles.icon_size"
                        ></v-slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item style="padding-top: 15px;">
                <div style="display: flex;flex:1;align-items: center;margin-bottom:15px;">
                  <label class="label" style="min-width:250px;display:block;margin-right:15px;margin-bottom:0;">Change date period</label>
                  <DatePicker
                      :shortcuts="shortcuts"
                      format="DD.MM.YYYY"
                      style="max-width:320px;"
                      :disabled="allDates"
                      v-model="addIntroBannerModel.dates"
                      type="date"
                      @change="changeDates"
                      range
                      placeholder="Select date period"
                  >
                    <template v-slot:input>
                      <v-text-field
                          solo
                          flat
                          style="max-width:320px;"
                          outlined
                          :disabled="allDates"
                          dense
                          :placeholder="$t('statistics[\'Период\']')"
                          readonly
                          hide-details
                          v-model="addIntroBannerModel.date_model"
                      ></v-text-field>
                    </template>
                  </DatePicker>
                  <label class="label" style="margin:0 15px;">Show not limited by period</label><v-switch color="#6ab64a" hide-details style="margin:0;padding:0;" v-model="allDates" @change="setShowAllTime"></v-switch>
                </div>
              <div style="display: flex;flex:1;align-items: center;margin-bottom:15px;">
                <label class="label"  style="min-width:250px;display:block;margin-right:15px;margin-bottom:0;">Show per day</label>
                <v-text-field type="number" min="1"
                              style="max-width:320px;"
                              solo
                              flat
                              outlined
                              dense
                              hide-details v-model.number=addIntroBannerModel.styles.show_per_day></v-text-field>
              </div>
              <div style="padding:0;margin-bottom:15px;display:flex;align-items:center;">
                <label class="label" style="min-width:250px;display:block;margin-right:15px;margin-bottom:0;">Show not limited per time</label>
                <v-switch color="#6ab64a" style="margin:0;padding:0;" hide-details v-model="allTimes" @change="changeAllWeek"></v-switch>
              </div>
              <div v-if="!allTimes" style="display: flex;margin-top:15px;width:100%;">
                <div style="display: flex;flex-direction: column;width:100%;">
                  <div v-for="(times,i) in addIntroBannerModel.week" :key="i" style="border-bottom:1px solid #ccc;display: flex;flex-direction: column;justify-content: center;margin-bottom:10px;width:100%;">
                    <div style="display: flex;padding:10px;justify-content: space-between;">
                      <div>
                        <label class="label">{{days[i]}}</label>
                      </div>
                      <div style="display: flex;flex-direction: column;padding:15px;">
                        <div style="display:flex;margin-bottom:5px;padding:5px;align-items: center;" v-for="(time,index) in indexArr(times)" :key="index+'start'" >
                          <div style="display: flex;flex-direction: column;margin-right:15px;">
                            <label style="display:block;margin-bottom: 10px;">From</label>
                            <VueTimepicker hide-clear-button  format="HH:mm"  v-model="times.time_start[index]"/>
                          </div>
                          <div style="display: flex;flex-direction: column;margin-right:15px;">
                            <label style="display:block;margin-bottom: 10px;">To</label>
                            <VueTimepicker hide-clear-button  format="HH:mm" v-model="times.time_end[index]"/>
                          </div>
                          <v-icon @click="deletePeriod(times,index)" small style="position: relative;top:15px;" color="grey">mdi-close</v-icon>
                        </div>
                        <div style="display: flex;margin-bottom:5px;padding:5px;align-items:center;" v-if="!indexArr(times).length">
                          No added time period
                        </div>
                      </div>
                      <div>
                        <v-btn outlined small color="primary"  @click="addPeriod(times)"><v-icon small>mdi-plus</v-icon> add period</v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item style="padding-top:15px;">
              <div style="display:flex;align-items: center;flex:1;margin-bottom:15px;">
                <label class="label" style="display:block;margin-bottom:0;margin-right:15px;width:200px;">Selected Segments</label>
                <v-combobox
                    hide-details
                    v-model="selectedSegments"
                    class="canvas__select"
                    item-text="name"
                    item-value="id"
                    :items="segments"
                    multiple
                    outlined
                    dense
                    flat
                >
                </v-combobox>
              </div>
            </v-tab-item>
          </v-tabs>
          <div v-if="!addBannerDialogTabs" style="position:sticky;top:0;height:100%;width:400px;border-radius:5px;background-color: rgba(0, 125, 181, 0.12);padding: 20px;">
            <label class="label" style="display: block;margin-bottom: 15px;">Intro preview</label>
            <div style="overflow:hidden;height:570px;width:360px;max-height: 570px;max-width:360px;background: #fff;position:relative;border-radius:5px;display: flex;justify-content: center;align-items: center;">
              <div class="intro_preview"  v-if="intro_type_0_preview" style="position:absolute;top:0;left:0;height:570px;width:360px;">

              </div>
              <v-btn
                  style="
                position: absolute;
                z-index:200;
                top: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                left: 5px;
                background-color: rgba(255, 255, 255, 0.4);
              "
                  small
                  depressed
              >Skip {{ currentTime }} s</v-btn
              >
              <v-icon style="position:absolute;cursor: pointer;z-index: 251;" :style="`right:0;top:0;`" :color="addIntroBannerModel.styles.icon_color" :size="addIntroBannerModel.styles.icon_size">{{addIntroBannerModel.styles.icon}}</v-icon>

              <img v-if="addIntroBannerModel.type === 0" :src="addIntroBannerModel.image[landing.current_lang] ? addIntroBannerModel.image[landing.current_lang] : require('@/assets/none.png')" :style="`height:100%;width:100%;`" alt="">
              <video
                  v-if="addIntroBannerModel.type === 1 && addIntroBannerModel.video[landing.current_lang]"
                  :src="addIntroBannerModel.video[landing.current_lang]"
                  playsinline
                  autoplay
                  muted
                  loop
                  height="570px"
                  width="360px"
                  style="
                      position: absolute;
                      object-fit: cover;
                      z-index: 250;
                    "
              ></video>
              <div v-if="addIntroBannerModel.type === 2">
                <div v-if="intro_upload_pro_top_img" class="intro_top_img_preview" style="overflow:hidden;width:360px;height:200px;position:absolute;top:0;left:0;">

                </div>
                <div v-else style="width:360px;height:200px;position:absolute;top:0;left:0;">
                  <img :src="addIntroBannerModel.pro[landing.current_lang].top_img?addIntroBannerModel.pro[landing.current_lang].top_img:require('@/assets/none.png')" alt="">
                </div>
                <div v-if="intro_upload_pro_logo_img" class="intro_logo_img_preview" :style="`overflow:hidden;width:${addIntroBannerModel.pro.logo_width}px;height:${addIntroBannerModel.pro.logo_height}px;position:absolute;top:150px;left:50%;margin-left:-${+addIntroBannerModel.pro.logo_width/2}px;`"></div>
                <div v-else :style="`width:${addIntroBannerModel.pro.logo_width}px;height:${addIntroBannerModel.pro.logo_height}px;position:absolute;top:150px;left:50%;margin-left:-${+addIntroBannerModel.pro.logo_width/2}px;`">
                  <img :src="addIntroBannerModel.pro[landing.current_lang].logo_img?addIntroBannerModel.pro[landing.current_lang].logo_img:require('@/assets/none.png')" style="height:100%;width:100%;" alt="">
                </div>
                <div
                    :style="`position:absolute;left:0;top:${+addIntroBannerModel.pro.logo_height+175}px;width:100%;`"
                    v-html="htmlData(addIntroBannerModel.pro[landing.current_lang].main_description)"
                ></div>
                <div style="position:absolute;bottom:0;width:100%;">
                  <v-icon>{{addIntroBannerModel.pro[landing.current_lang].btn_next}}</v-icon>
                  <div v-html="htmlData(addIntroBannerModel.pro[landing.current_lang].text_next)">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions style="padding: 20px 24px">
          <v-spacer></v-spacer>
          <v-btn
              class="object__submit btn btn_primary"
              color="primary"
              depressed
              outlined
              @click="addIntroBannerConfirm"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PopupSuccess ref="PopupSuccess" :title="'Success!'" :content="successMessage" />
    <PopupCrop title='Change image before upload?' ref="PopupCrop" @confirm="openEditor" @save="saveImg" @close="closeCrop"/>
    <PopupCrop title='Change image before upload?' ref="PopupCropTopPro" @confirm="openEditorTopPro" @save="saveImgTopPro" @close="closeCropTopPro"/>
    <PopupCrop title='Change image before upload?' ref="PopupCropTopLogo" @confirm="openEditorTopLogo" @save="saveImgTopLogo" @close="closeCropTopLogo"/>
    <PopupSuccess ref="PopupSuccess" :title="'Success!'" :content="successMessage" />
    <PopupDelete
        ref="PopupDelete"
        @confirm="deleteBannerConfirm"
        :title="'Are you sure about delete?'"
    />
  </div>
</template>

<script>
import store from "@/store";
import {mapActions,mapState} from "vuex";
import titleMixin from "@/mixins/titleMixin";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vue2-datepicker/index.css";
import 'vue2-timepicker/dist/VueTimepicker.css'
import "../../../node_modules/cropperjs/dist/cropper.min.css";
import Compressor from "compressorjs";
import Cropper from "cropperjs";
import DatePicker from "vue2-datepicker";

export default {
  name: "PopupIntro",
  props:['addIntroBannerModel','addIntroBannerDialog','objectItem','type'],
  components:{
    vueDropzone:() => import("vue2-dropzone"),
    VueTimepicker:() => import("vue2-timepicker"),
    PopupCrop:() => import("@/components/Popup/PopupCrop"),
    PopupSuccess:() => import("@/components/Popup/PopupSuccess"),
    PopupDelete:() => import("@/components/Popup/PopupDelete"),
    DatePicker
  },
  data(){
    return{
      selectedSegments:[],
      allDates:false,
      allTimes:true,
      bannerTypes:[
        {value:0,label:"Image"},
        {value:1,label:"Video"},
        {value:2,label:"Pro"},
      ],
      deleteBannerID:null,
      successMessage:"",
      next_icons:[
        'mdi-arrow-right',
        'mdi-arrow-right-bold',
        'mdi-arrow-right-bold-box',
        'mdi-arrow-right-bold-box-outline',
        'mdi-arrow-right-bold-circle',
        'mdi-arrow-right-bold-circle-outline',
        'mdi-arrow-right-bold-hexagon-outline',
        'mdi-arrow-right-bold-outline',
        'mdi-arrow-right-box',
        'mdi-arrow-right-circle',
        'mdi-arrow-right-circle-outline',
        'mdi-arrow-right-drop-circle',
        'mdi-arrow-right-drop-circle-outline',
        'mdi-arrow-right-thin-circle-outline',
        'mdi-chevron-right',
        'mdi-menu-right',
      ],
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: 'jpg,svg,webp,gif,png',
          images_upload_handler: async  (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
            formData.append("lending_settings_id", this.$store.state.landing.settings_id);
            formData.append("image", _file);
            formData.append("file", _file);
            const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
            success(data);
          },
          plugins:
              "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

        setup:function(editor){
          editor.ui.registry.addButton('custom_button', {
            text: 'Add Button',
            onAction: function() {
              // Open a Dialog
              editor.windowManager.open({
                title: 'Add custom button',
                body: {
                  type: 'panel',
                  items: [{
                    type: 'input',
                    name: 'button_label',
                    label: 'Button label',
                    flex: true
                  },{
                    type: 'input',
                    name: 'button_href',
                    label: 'Button href',
                    flex: true
                  },{
                    type: 'selectbox',
                    name: 'button_target',
                    label: 'Target',
                    items: [
                      {text: 'None', value: ''},
                      {text: 'New window', value: '_blank'},
                      {text: 'Self', value: '_self'},
                      {text: 'Parent', value: '_parent'}
                    ],
                    flex: true
                  },{
                    type: 'selectbox',
                    name: 'button_style',
                    label: 'Style',
                    items: [
                      {text: 'Success', value: 'success'},
                      {text: 'Info', value: 'info'},
                      {text: 'Warning', value: 'warning'},
                      {text: 'Error', value: 'error'}
                    ],
                    flex: true
                  }]
                },
                onSubmit: function (api) {

                  var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                  // insert markup
                  editor.insertContent(html);

                  // close the dialog
                  api.close();
                },
                buttons: [
                  {
                    text: 'Close',
                    type: 'cancel',
                    onclick: 'close'
                  },
                  {
                    text: 'Insert',
                    type: 'submit',
                    primary: true,
                    enabled: false
                  }
                ]
              });
            }
          });
        },
        content_css: ["/custom_tinymce.css"],
      },
      intro_upload_pro_logo_img:false,
      intro_upload_pro_top_img:false,
      intro_type_0_preview:false,
      timer: null,
      currentTime:5,
      addBannerDialogTabs:0,
      days:[
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],
      shortcuts: [
        {
          text: "Today",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 1 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "Yesterday",
          onClick() {
            const start = new Date();
            const end = new Date();
            end.setTime(end.getTime() + 1 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "Last 7 day",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 7 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "Last 30 day",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 30 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "This month",
          onClick() {
            const start = new Date();
            const end = new Date();
            const firstDayCurrentMonth = new Date(
                start.getFullYear(),
                start.getMonth(),
                1
            );
            const lastDayCurrefntMonth = new Date(
                end.getFullYear(),
                end.getMonth() + 1,
                0
            );
            const date = [firstDayCurrentMonth, lastDayCurrefntMonth];
            return date;
          },
        },
        {
          text: "Last month",
          onClick() {
            const dateLast = new Date();
            const firstDayCurrentMonth = new Date(
                dateLast.getFullYear(),
                dateLast.getMonth() - 1,
                1
            );
            const lastDayCurrefntMonth = new Date(
                dateLast.getFullYear(),
                dateLast.getMonth(),
                0
            );
            const date = [firstDayCurrentMonth, lastDayCurrefntMonth];
            return date;
          },
        },
      ],
      icons:['mdi-close','mdi-close-box','mdi-close-box-outline','mdi-close-circle',
        'mdi-close-circle-outline','mdi-close-octagon','mdi-close-octagon-outline','mdi-close-outline','mdi-close-network'],
      addIntroBannerModelIconColor:false,
      addIntroBannerModelBg:false,
      headers: [
        { text: "Image", value: "image", align: "center", sortable: true },
        { text: "Name", value: "date", align: "center", sortable: true },
        { text: "Type", value: "type", align: "center", sortable: false },
        { text: "Date Period", align: "center" },
        { text: "Active", value:"is_active",align: "center" },
        { text: "Options", align: "center", sortable: false },
      ],
      selected_lang:"en",
      defaultIntroBanner:{
        image: `${process.env.VUE_APP_LINK}/banner_default.jpg`,
        video: null,
        show_time:5,
        repeat: 0,
        name:{
          en:"default name"
        },
        is_active:1,
        week:{
          0:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }
            ],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          1:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          2:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          3:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          4:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          5:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
          6:{
            time_start:[
              {
                HH:'00',
                mm:'00',
              }],
            time_end:[
              {
                HH:'23',
                mm:'59',
              }],
          },
        },
        date_model:"",
        date_to:null,
        date_from:null,
        dates:[],
        hotel_id:null,
        rooms:[],
        pro: {
          top_img: `${process.env.VUE_APP_LINK}/banner_default.jpg`,
          logo_img: `${process.env.VUE_APP_LINK}/banner_default.jpg`,
          main_description: "<p ><strong>WELCOME</strong></p>",
          btn_next: "mdi-arrow-right-circle",
          text_next: "<p ><strong>SKIP</strong></p>",
          logo_width: "100",
          logo_height: "100",
        },
        type: 0,
        styles:{
          icon: "mdi-close-circle-outline",
          bg: "#ffffff",
          icon_color: "#ffffff",
          icon_size: 24,
        },
        segment_ids:[]
      },
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        maxFiles: 1,
        destroyDropzone: false,
        dictFileTooBig:"File is too big ({{filesize}}mb). Max file size: 3mb",
        autoProcessQueue: false,
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          }else{
            this.compress(file)
          }
        },
      },
      dropzoneOptionsTopPro: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        maxFiles: 1,
        destroyDropzone: false,
        dictFileTooBig:"File is too big ({{filesize}}mb). Max file size: 3mb",
        autoProcessQueue: false,
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          }else{
            this.compressTopPro(file)
          }
        },
      },
      dropzoneOptionsTopLogo: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        maxFiles: 1,
        destroyDropzone: false,
        dictFileTooBig:"File is too big ({{filesize}}mb). Max file size: 3mb",
        autoProcessQueue: false,
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          }else{
            this.compressTopLogo(file)
          }
        },
      },
      introTypes:{
        0:"Image",
        1:"Video",
        2:"Pro"
      }
    }
  },
  async mounted(){
    this.allDates = !!this.addIntroBannerModel.show_all_time;
    await this.getSegments(this.objectItem.id);
    if(this.addIntroBannerModel.segment_ids.length){
      this.selectedSegments = JSON.parse(JSON.stringify(this.segments.reduce((acc,item) => {
        if(this.addIntroBannerModel.segment_ids.indexOf(item.id) >= 0){
          const candidate = {
            name:item.name,
            id:item.id
          };
          acc.push(candidate);
        }
        return acc;
      },[])));
    }
    },
  computed: {
    ...mapState(["objects"]),
    ...mapState('intro',['introList']),
    ...mapState("landing", ["selected_langs"]),
    ...mapState(['landing']),
    ...mapState('rooms',['roomCategories']),
    ...mapState('segments',['segments']),
    roomList(){
      return this.roomCategories.reduce((acc,item) => {
        const candidate = this.addIntroBannerModel.room_categories.find(x => x.id === item.id);
        if(!candidate){
          acc = [...acc,...item.rooms];
        }
        return acc;
      },[])
    },
  },
  watch:{
    currentTime(time) {
      if (time === 0) {
        this.currentTime = this.addIntroBannerModel.show_time;
      }
    },
  },
  methods:{
    ...mapActions('intro',['getIntroBanners','postIntroBanner','putIntroBanner','deleteIntroBanner']),
    ...mapActions('segments',['getSegments']),
    changeAllWeek(){
      if(this.allTimes){
        this.addIntroBannerModel.show_all_week = 1;
      }else{
        this.addIntroBannerModel.show_all_week = 0;
      }
    },
    changeLang(lang){
      this.landing.current_lang = lang;
    },
    setShowAllTime(){
      if(this.allDates){
        this.addIntroBannerModel.show_all_time = 1;
      }else{
        this.addIntroBannerModel.show_all_time = 0;
      }
    },
    mountType0(){
      let elements =
          this.$refs.uploadType0.$el.getElementsByClassName("dz-preview");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      if(this.addIntroBannerModel.image[this.landing.current_lang]){
        let mockFile = {
          accepted: true,
          cropped:true,
          kind: "image",
        };
        this.$refs.uploadType0.manuallyAddFile(mockFile, this.addIntroBannerModel.image[this.landing.current_lang]);
        this.$refs.uploadType0.dropzone.emit("thumbnail", mockFile, this.addIntroBannerModel.image[this.landing.current_lang]);
        this.$refs.uploadType0.dropzone.emit("complete", mockFile);
        this.$refs.uploadType0.dropzone['previewsContainer'].children[1].children[0].children[0].src = this.addIntroBannerModel.image[this.landing.current_lang];
        this.$refs.uploadType0.dropzone['previewsContainer'].children[1].children[0].style.borderRadius = 0;
        this.$refs.uploadType0.dropzone['previewsContainer'].children[1].children[1].style.background = 'none';
      }
    },
    mountTopPro(){
      let elements =
          this.$refs.uploadTopPro.$el.getElementsByClassName("dz-preview");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      if(this.addIntroBannerModel.pro[this.landing.current_lang].top_img){
        let mockFile = {
          accepted: true,
          cropped:true,
          kind: "image",
        };
        this.$refs.uploadTopPro.manuallyAddFile(mockFile, this.addIntroBannerModel.pro[this.landing.current_lang].top_img);
        this.$refs.uploadTopPro.dropzone.emit("thumbnail", mockFile, this.addIntroBannerModel.pro[this.landing.current_lang].top_img);
        this.$refs.uploadTopPro.dropzone.emit("complete", mockFile);
        this.$refs.uploadTopPro.dropzone['previewsContainer'].children[1].children[0].children[0].src = this.addIntroBannerModel.pro[this.landing.current_lang].top_img;
        this.$refs.uploadTopPro.dropzone['previewsContainer'].children[1].children[0].style.borderRadius = 0;
        this.$refs.uploadTopPro.dropzone['previewsContainer'].children[1].children[1].style.background = 'none';
      }
    },
    mountTopLogo(){
      let elements =
          this.$refs.uploadTopLogo.$el.getElementsByClassName("dz-preview");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      if(this.addIntroBannerModel.pro[this.landing.current_lang].logo_img){
        let mockFile = {
          accepted: true,
          cropped:true,
          kind: "image",
        };
        this.$refs.uploadTopLogo.manuallyAddFile(mockFile, this.addIntroBannerModel.pro[this.landing.current_lang].logo_img);
        this.$refs.uploadTopLogo.dropzone.emit("thumbnail", mockFile, this.addIntroBannerModel.pro[this.landing.current_lang].logo_img);
        this.$refs.uploadTopLogo.dropzone.emit("complete", mockFile);
        this.$refs.uploadTopLogo.dropzone['previewsContainer'].children[1].children[0].children[0].src = this.addIntroBannerModel.pro[this.landing.current_lang].logo_img;
        this.$refs.uploadTopLogo.dropzone['previewsContainer'].children[1].children[0].style.borderRadius = 0;
        this.$refs.uploadTopLogo.dropzone['previewsContainer'].children[1].children[1].style.background = 'none';
      }
    },
    openDelete(id){
      this.deleteBannerID = id;
      this.$refs.PopupDelete.open();
    },
    async deleteBannerConfirm(){
      await this.deleteIntroBanner(this.deleteBannerID);
      this.successMessage = 'You intro banner successfully deleted!';
      this.$refs.PopupSuccess.open();
    },
    async changeActive(banner){
      if(banner.is_active){
        banner.is_active = 0;
      }else{
        banner.is_active = 1;
      }
      await this.putIntroBanner(banner);
    },
    changeRepeat(){
      if(this.addIntroBannerModel.repeat){
        this.addIntroBannerModel.repeat = 0;
      }else{
        this.addIntroBannerModel.repeat = 1;
      }
    },
    htmlData(data, type) {
      if (data) {
        const weatherInd = data.split(" ").indexOf(":weather:");
        let result = data.split(" ");
        if (weatherInd && this.landing.advanced.global.show_weather) {
          const newArr = result;
          newArr[weatherInd] =
              this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
                  0
              ) + " ℃";
          result = newArr;
        }
        const timeInd = data.split(" ").indexOf(":time:");
        if (timeInd) {
          const newArr = result;
          newArr[timeInd] = this.datenow;
          result = newArr;
        }
        const dateInd = data.split(" ").indexOf(":date:");
        if (dateInd) {
          const newArr = result;
          newArr[dateInd] = this.dateToday;
          result = newArr;
        }
        const user_name = data.split(" ").indexOf(":name:");
        if (
            user_name &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
        ) {
          const newArr = result;
          newArr[user_name] = "Agent";
          result = newArr;
        }
        const user_surname = data.split(" ").indexOf(":surname:");
        if (
            user_surname &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
        ) {
          const newArr = result;
          newArr[user_surname] = "Smith";
          result = newArr;
        }
        const user_birthday = data.split(" ").indexOf(":birthday:");
        if (
            user_surname &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
        ) {
          const newArr = result;
          newArr[user_birthday] = "24.03.1999";
          result = newArr;
        }
        const user_room = data.split(" ").indexOf(":room:");
        if (
            user_surname &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
        ) {
          const newArr = result;
          newArr[user_room] = "707";
          result = newArr;
        }

        const user_auth = data.split(" ").indexOf(":auth:");
        if (user_auth && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_auth] = `<a href="#">Login</a>`;
          result = newArr;
        }
        const user_logout = data.split(" ").indexOf(":logout:");
        if (user_logout && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_logout] = `<a href="#">Logout</a>`;
          result = newArr;
        }
        return result.join(" ");
      }
    },
    compress(file){
      new Compressor(file, {
        quality: 0.6,
        mimeType:'image/webp',
        convertSize:0,
        convertTypes:['image/jpeg','image/png','image/jpg'],
        success:(result) => {
          if(this.$store.state.isMobile){
            this.saveImg(result)
          }else{
            this.$refs.PopupCrop.open(result)
          }
        },
        error(e) {
          console.log(e);
        }
      })
    },
    compressTopPro(file){
      new Compressor(file, {
        quality: 0.6,
        mimeType:'image/webp',
        convertSize:0,
        convertTypes:['image/jpeg','image/png','image/jpg'],
        success:(result) => {
          this.$refs.PopupCropTopPro.open(result)
        },
        error(e) {
          console.log(e);
        }
      })
    },
    compressTopLogo(file){
      new Compressor(file, {
        quality: 0.6,
        mimeType:'image/webp',
        convertSize:0,
        convertTypes:['image/jpeg','image/png','image/jpg'],
        success:(result) => {
          this.$refs.PopupCropTopLogo.open(result)
        },
        error(e) {
          console.log(e);
        }
      })
    },
    blobToDataURL(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = _e => resolve(reader.result);
        reader.onerror = _e => reject(reader.error);
        reader.onabort = _e => reject(new Error("Read aborted"));
        reader.readAsDataURL(blob);
      });
    },
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    async saveImg(file){
      file.cropped = true;
      this.$refs.uploadType0.dropzone.removeAllFiles();
      this.$refs.uploadType0.dropzone.addFile(file);
      this.$refs.uploadType0.dropzone.processQueue();
      this.$refs.uploadType0.dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = await this.blobToDataURL(file);
      this.$refs.uploadType0.dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
      this.$refs.uploadType0.dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
    },
    openEditor(file){
      let editor = document.createElement("div");
      editor.style.position = "fixed";
      editor.style.left = 0;
      editor.style.right = 0;
      editor.style.top = 0;
      editor.style.bottom = 0;
      editor.style.width = "50%";
      editor.style.zIndex = 9999;
      editor.style.backgroundColor = "#000";
      document.body.appendChild(editor);
      if (this.addIntroBannerModel.image[this.landing.current_lang]) {
        this.$refs.uploadType0.dropzone.removeFile(file);
      }
      let buttonConfirm = document.createElement("button");
      buttonConfirm.style.position = "absolute";
      buttonConfirm.style.right = "10px";
      buttonConfirm.style.top = "10px";
      buttonConfirm.style.zIndex = 9999;
      buttonConfirm.textContent = "Save image";
      buttonConfirm.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonConfirm);
      let buttonCancel = document.createElement("button");
      buttonCancel.style.position = "absolute";
      buttonCancel.style.right = "135px";
      buttonCancel.style.top = "10px";
      buttonCancel.style.zIndex = 9999;
      buttonCancel.textContent = "Cancel";
      buttonCancel.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonCancel);
      buttonCancel.addEventListener("click", () => {
        this.$refs.uploadType0.dropzone.removeAllFiles();
        document.body.removeChild(editor);
        cropper.destroy();
      });
      let buttonRotateLeft = document.createElement("button");
      buttonRotateLeft.className = "cropper-controls";
      buttonRotateLeft.style.left = "24px";
      buttonRotateLeft.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
      editor.appendChild(buttonRotateLeft);
      let buttonRotateRight = document.createElement("button");
      buttonRotateRight.className = "cropper-controls";
      buttonRotateRight.style.left = "64px";
      buttonRotateRight.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
      editor.appendChild(buttonRotateRight);
      let buttonCrop = document.createElement("button");
      buttonCrop.className = "cropper-controls active-control";
      buttonCrop.style.left = "104px";
      buttonCrop.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
      editor.appendChild(buttonCrop);
      let buttonZoomPlus = document.createElement("button");
      buttonZoomPlus.className = "cropper-controls";
      buttonZoomPlus.style.left = "144px";
      buttonZoomPlus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
      editor.appendChild(buttonZoomPlus);
      let buttonZoomMinus = document.createElement("button");
      buttonZoomMinus.className = "cropper-controls";
      buttonZoomMinus.style.left = "184px";
      buttonZoomMinus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
      editor.appendChild(buttonZoomMinus);
      let buttonLeftRight = document.createElement("button");
      buttonLeftRight.className = "cropper-controls";
      buttonLeftRight.style.left = "224px";
      buttonLeftRight.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
      editor.appendChild(buttonLeftRight);
      let buttonUpDown = document.createElement("button");
      buttonUpDown.className = "cropper-controls";
      buttonUpDown.style.left = "264px";
      buttonUpDown.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
      editor.appendChild(buttonUpDown);
      buttonConfirm.addEventListener("click", () => {
        let canvas = cropper.getCroppedCanvas();
        let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
        newFile.cropped = true;
        newFile.name = file.name;
        this.$refs.uploadType0.dropzone.removeAllFiles();
        this.$refs.uploadType0.dropzone.addFile(newFile);
        this.$refs.uploadType0.dropzone.processQueue();
        this.$refs.uploadType0.dropzone[
            "previewsContainer"
            ].children[1].children[0].children[0].src =  canvas.toDataURL(file.type);
        this.$refs.uploadType0.dropzone[
            "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
        this.$refs.uploadType0.dropzone[
            "previewsContainer"
            ].children[1].children[1].style.background = "none";
        this.intro_type_0_preview = false;
        document.body.removeChild(editor);
        cropper.destroy();
      });
      buttonRotateRight.addEventListener("click", () => {
        if (!buttonRotateRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateRight.classList.add("active-control");
        }
        cropper.rotate(45);
      });
      buttonRotateLeft.addEventListener("click", () => {
        if (!buttonRotateLeft.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateLeft.classList.add("active-control");
        }
        cropper.rotate(-45);
      });

      buttonCrop.addEventListener("click", () => {
        if (!buttonCrop.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonCrop.classList.add("active-control");
        }
        cropper.setDragMode("crop");
      });
      buttonZoomPlus.addEventListener("click", () => {
        if (!buttonZoomPlus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomPlus.classList.add("active-control");
        }
        cropper.zoom(0.1);
      });
      buttonZoomMinus.addEventListener("click", () => {
        if (!buttonZoomMinus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomMinus.classList.add("active-control");
        }
        cropper.zoom(-0.1);
      });
      buttonLeftRight.addEventListener("click", () => {
        if (!buttonLeftRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonLeftRight.classList.add("active-control");
        }
        if (cropper.getData().scaleX === 1) {
          cropper.scaleX(-1);
        } else {
          cropper.scaleX(1);
        }
      });
      buttonUpDown.addEventListener("click", () => {
        if (!buttonUpDown.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonUpDown.classList.add("active-control");
        }
        if (cropper.getData().scaleY === 1) {
          cropper.scaleY(-1);
        } else {
          cropper.scaleY(1);
        }
      });
      let image = new Image();
      image.src = URL.createObjectURL(file);
      editor.appendChild(image);
      this.intro_type_0_preview = true;
      let cropper = new Cropper(image, {
        preview: ".intro_preview",
        aspectRatio: 0.63157894736,
      });
    },
    closeCrop(){
      this.$refs.uploadType0.dropzone.removeAllFiles();
    },

    async saveImgTopPro(file){
      file.cropped = true;
      this.$refs.uploadTopPro.dropzone.removeAllFiles();
      this.$refs.uploadTopPro.dropzone.addFile(file);
      this.$refs.uploadTopPro.dropzone.processQueue();
      this.$refs.uploadTopPro.dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = await this.blobToDataURL(file);
      this.$refs.uploadTopPro.dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
      this.$refs.uploadTopPro.dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
    },
    closeCropTopPro(){
      this.$refs.uploadTopPro.dropzone.removeAllFiles();
    },
    openEditorTopPro(file){
      let editor = document.createElement("div");
      editor.style.position = "fixed";
      editor.style.left = 0;
      editor.style.right = 0;
      editor.style.top = 0;
      editor.style.bottom = 0;
      editor.style.width = "66%";
      editor.style.zIndex = 9999;
      editor.style.backgroundColor = "#000";
      document.body.appendChild(editor);
      if (this.addIntroBannerModel.pro[this.landing.current_lang].top_img) {
        this.$refs.uploadTopPro.dropzone.removeFile(file);
      }
      let buttonConfirm = document.createElement("button");
      buttonConfirm.style.position = "absolute";
      buttonConfirm.style.right = "10px";
      buttonConfirm.style.top = "10px";
      buttonConfirm.style.zIndex = 9999;
      buttonConfirm.textContent = "Save image";
      buttonConfirm.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonConfirm);
      let buttonCancel = document.createElement("button");
      buttonCancel.style.position = "absolute";
      buttonCancel.style.right = "135px";
      buttonCancel.style.top = "10px";
      buttonCancel.style.zIndex = 9999;
      buttonCancel.textContent = "Cancel";
      buttonCancel.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonCancel);
      buttonCancel.addEventListener("click", () => {
        this.$refs.uploadTopPro.dropzone.removeAllFiles();
        document.body.removeChild(editor);
        cropper.destroy();
      });
      let buttonRotateLeft = document.createElement("button");
      buttonRotateLeft.className = "cropper-controls";
      buttonRotateLeft.style.left = "24px";
      buttonRotateLeft.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
      editor.appendChild(buttonRotateLeft);
      let buttonRotateRight = document.createElement("button");
      buttonRotateRight.className = "cropper-controls";
      buttonRotateRight.style.left = "64px";
      buttonRotateRight.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
      editor.appendChild(buttonRotateRight);
      let buttonCrop = document.createElement("button");
      buttonCrop.className = "cropper-controls active-control";
      buttonCrop.style.left = "104px";
      buttonCrop.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
      editor.appendChild(buttonCrop);
      let buttonZoomPlus = document.createElement("button");
      buttonZoomPlus.className = "cropper-controls";
      buttonZoomPlus.style.left = "144px";
      buttonZoomPlus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
      editor.appendChild(buttonZoomPlus);
      let buttonZoomMinus = document.createElement("button");
      buttonZoomMinus.className = "cropper-controls";
      buttonZoomMinus.style.left = "184px";
      buttonZoomMinus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
      editor.appendChild(buttonZoomMinus);
      let buttonLeftRight = document.createElement("button");
      buttonLeftRight.className = "cropper-controls";
      buttonLeftRight.style.left = "224px";
      buttonLeftRight.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
      editor.appendChild(buttonLeftRight);
      let buttonUpDown = document.createElement("button");
      buttonUpDown.className = "cropper-controls";
      buttonUpDown.style.left = "264px";
      buttonUpDown.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
      editor.appendChild(buttonUpDown);
      buttonConfirm.addEventListener("click", () => {
        let canvas = cropper.getCroppedCanvas();
        let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
        newFile.cropped = true;
        newFile.name = file.name;
        this.$refs.uploadTopPro.dropzone.removeAllFiles();
        this.$refs.uploadTopPro.dropzone.addFile(newFile);
        this.$refs.uploadTopPro.dropzone.processQueue();
        this.$refs.uploadTopPro.dropzone[
            "previewsContainer"
            ].children[1].children[0].children[0].src =  canvas.toDataURL(file.type);
        this.$refs.uploadTopPro.dropzone[
            "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
        this.$refs.uploadTopPro.dropzone[
            "previewsContainer"
            ].children[1].children[1].style.background = "none";
        this.intro_upload_pro_top_img = false;
        document.body.removeChild(editor);
        cropper.destroy();
      });
      buttonRotateRight.addEventListener("click", () => {
        if (!buttonRotateRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateRight.classList.add("active-control");
        }
        cropper.rotate(45);
      });
      buttonRotateLeft.addEventListener("click", () => {
        if (!buttonRotateLeft.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateLeft.classList.add("active-control");
        }
        cropper.rotate(-45);
      });

      buttonCrop.addEventListener("click", () => {
        if (!buttonCrop.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonCrop.classList.add("active-control");
        }
        cropper.setDragMode("crop");
      });
      buttonZoomPlus.addEventListener("click", () => {
        if (!buttonZoomPlus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomPlus.classList.add("active-control");
        }
        cropper.zoom(0.1);
      });
      buttonZoomMinus.addEventListener("click", () => {
        if (!buttonZoomMinus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomMinus.classList.add("active-control");
        }
        cropper.zoom(-0.1);
      });
      buttonLeftRight.addEventListener("click", () => {
        if (!buttonLeftRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonLeftRight.classList.add("active-control");
        }
        if (cropper.getData().scaleX === 1) {
          cropper.scaleX(-1);
        } else {
          cropper.scaleX(1);
        }
      });
      buttonUpDown.addEventListener("click", () => {
        if (!buttonUpDown.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonUpDown.classList.add("active-control");
        }
        if (cropper.getData().scaleY === 1) {
          cropper.scaleY(-1);
        } else {
          cropper.scaleY(1);
        }
      });
      let image = new Image();
      image.src = URL.createObjectURL(file);
      editor.appendChild(image);
      this.intro_upload_pro_top_img = true;
      let cropper = new Cropper(image, {
        preview: ".intro_top_img_preview",
        aspectRatio: 360/200,
      });
    },
    async saveImgTopLogo(file){
      file.cropped = true;
      this.$refs.uploadTopLogo.dropzone.removeAllFiles();
      this.$refs.uploadTopLogo.dropzone.addFile(file);
      this.$refs.uploadTopLogo.dropzone.processQueue();
      this.$refs.uploadTopLogo.dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = await this.blobToDataURL(file);
      this.$refs.uploadTopLogo.dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
      this.$refs.uploadTopLogo.dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
    },
    closeCropTopLogo(){
      this.$refs.uploadTopLogo.dropzone.removeAllFiles();
    },
    openEditorTopLogo(file){
      let editor = document.createElement("div");
      editor.style.position = "fixed";
      editor.style.left = 0;
      editor.style.right = 0;
      editor.style.top = 0;
      editor.style.bottom = 0;
      editor.style.width = "66%";
      editor.style.zIndex = 9999;
      editor.style.backgroundColor = "#000";
      document.body.appendChild(editor);
      if (this.addIntroBannerModel.pro[this.landing.current_lang].logo_img) {
        this.$refs.uploadTopLogo.dropzone.removeFile(file);
      }
      let buttonConfirm = document.createElement("button");
      buttonConfirm.style.position = "absolute";
      buttonConfirm.style.right = "10px";
      buttonConfirm.style.top = "10px";
      buttonConfirm.style.zIndex = 9999;
      buttonConfirm.textContent = "Save image";
      buttonConfirm.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonConfirm);
      let buttonCancel = document.createElement("button");
      buttonCancel.style.position = "absolute";
      buttonCancel.style.right = "135px";
      buttonCancel.style.top = "10px";
      buttonCancel.style.zIndex = 9999;
      buttonCancel.textContent = "Cancel";
      buttonCancel.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonCancel);
      buttonCancel.addEventListener("click", () => {
        this.$refs.uploadTopLogo.dropzone.removeAllFiles();
        document.body.removeChild(editor);
        cropper.destroy();
      });
      let buttonRotateLeft = document.createElement("button");
      buttonRotateLeft.className = "cropper-controls";
      buttonRotateLeft.style.left = "24px";
      buttonRotateLeft.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
      editor.appendChild(buttonRotateLeft);
      let buttonRotateRight = document.createElement("button");
      buttonRotateRight.className = "cropper-controls";
      buttonRotateRight.style.left = "64px";
      buttonRotateRight.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
      editor.appendChild(buttonRotateRight);
      let buttonCrop = document.createElement("button");
      buttonCrop.className = "cropper-controls active-control";
      buttonCrop.style.left = "104px";
      buttonCrop.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
      editor.appendChild(buttonCrop);
      let buttonZoomPlus = document.createElement("button");
      buttonZoomPlus.className = "cropper-controls";
      buttonZoomPlus.style.left = "144px";
      buttonZoomPlus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
      editor.appendChild(buttonZoomPlus);
      let buttonZoomMinus = document.createElement("button");
      buttonZoomMinus.className = "cropper-controls";
      buttonZoomMinus.style.left = "184px";
      buttonZoomMinus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
      editor.appendChild(buttonZoomMinus);
      let buttonLeftRight = document.createElement("button");
      buttonLeftRight.className = "cropper-controls";
      buttonLeftRight.style.left = "224px";
      buttonLeftRight.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
      editor.appendChild(buttonLeftRight);
      let buttonUpDown = document.createElement("button");
      buttonUpDown.className = "cropper-controls";
      buttonUpDown.style.left = "264px";
      buttonUpDown.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
      editor.appendChild(buttonUpDown);
      buttonConfirm.addEventListener("click", () => {
        let canvas = cropper.getCroppedCanvas();
        let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
        newFile.cropped = true;
        newFile.name = file.name;
        this.$refs.uploadTopLogo.dropzone.removeAllFiles();
        this.$refs.uploadTopLogo.dropzone.addFile(newFile);
        this.$refs.uploadTopLogo.dropzone.processQueue();
        this.$refs.uploadTopLogo.dropzone[
            "previewsContainer"
            ].children[1].children[0].children[0].src =  canvas.toDataURL(file.type);
        this.$refs.uploadTopLogo.dropzone[
            "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
        this.$refs.uploadTopLogo.dropzone[
            "previewsContainer"
            ].children[1].children[1].style.background = "none";
        this.intro_upload_pro_logo_img = false;
        document.body.removeChild(editor);
        cropper.destroy();
      });
      buttonRotateRight.addEventListener("click", () => {
        if (!buttonRotateRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateRight.classList.add("active-control");
        }
        cropper.rotate(45);
      });
      buttonRotateLeft.addEventListener("click", () => {
        if (!buttonRotateLeft.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateLeft.classList.add("active-control");
        }
        cropper.rotate(-45);
      });

      buttonCrop.addEventListener("click", () => {
        if (!buttonCrop.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonCrop.classList.add("active-control");
        }
        cropper.setDragMode("crop");
      });
      buttonZoomPlus.addEventListener("click", () => {
        if (!buttonZoomPlus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomPlus.classList.add("active-control");
        }
        cropper.zoom(0.1);
      });
      buttonZoomMinus.addEventListener("click", () => {
        if (!buttonZoomMinus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomMinus.classList.add("active-control");
        }
        cropper.zoom(-0.1);
      });
      buttonLeftRight.addEventListener("click", () => {
        if (!buttonLeftRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonLeftRight.classList.add("active-control");
        }
        if (cropper.getData().scaleX === 1) {
          cropper.scaleX(-1);
        } else {
          cropper.scaleX(1);
        }
      });
      buttonUpDown.addEventListener("click", () => {
        if (!buttonUpDown.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonUpDown.classList.add("active-control");
        }
        if (cropper.getData().scaleY === 1) {
          cropper.scaleY(-1);
        } else {
          cropper.scaleY(1);
        }
      });
      let image = new Image();
      image.src = URL.createObjectURL(file);
      editor.appendChild(image);
      this.intro_upload_pro_logo_img = true;
      let cropper = new Cropper(image, {
        preview: ".intro_logo_img_preview",
        aspectRatio: +this.addIntroBannerModel.pro.logo_width/+this.addIntroBannerModel.pro.logo_height,
      });
    },

    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--;
      }, 1000);
    },
    async onFileChange(file){
      try{
        if(file){
          let formData = new FormData();
          formData.append('lending_settings_id',this.landing.settings_id);
          formData.append('image',file);
          formData.append('file',file);
          const response = await this.$axios.post('upload-image',formData);
          this.addIntroBannerModel.video[this.landing.current_lang] = response.data;
        }
      }catch(e){
        console.log(e);
      }
    },
    changeDates() {
      if (this.addIntroBannerModel.dates[0] && this.addIntroBannerModel.dates[1]) {
        if (this.addIntroBannerModel.dates[0] > this.addIntroBannerModel.dates[1]) {
          let start = this.addIntroBannerModel.dates[0];
          let end = this.addIntroBannerModel.dates[1];
          this.addIntroBannerModel.dates[0] = end;
          this.addIntroBannerModel.dates[1] = start;
        }
      }
      this.dateRangeText();
    },
    dateRangeText() {
      let date_start = null;
      let date_end = null;
      if (this.addIntroBannerModel.dates[0] && this.addIntroBannerModel.dates[1]) {
        date_start = this.$moment(this.addIntroBannerModel.dates[0]).format("DD.MM.YYYY");
        date_end = this.$moment(this.addIntroBannerModel.dates[1]).format("DD.MM.YYYY");
      } else if (this.addIntroBannerModel.dates[0]) {
        date_start = this.$moment(this.addIntroBannerModel.dates[0]).format("DD.MM.YYYY");
      } else if (this.addIntroBannerModel.dates[1]) {
        date_end = this.$moment(this.addIntroBannerModel.dates[1]).format("DD.MM.YYYY");
      } else {
        this.addIntroBannerModel.date_model = "";
      }
      this.addIntroBannerModel.date_from = date_start;
      this.addIntroBannerModel.date_to = date_end;
      this.addIntroBannerModel.date_model = [date_start, date_end].join(" - ");
    },
    deletePeriod(times,index){
      times.time_start.splice(index,1);
      times.time_end.splice(index,1);
    },
    addPeriod(day){
      const defaultItemTimeStart = {
        HH:"00",
        mm:"00"
      }
      const defaultItemTimeEnd = {
        HH:"23",
        mm:"59"
      }
      day.time_start.push(defaultItemTimeStart);
      day.time_end.push(defaultItemTimeEnd);
    },
    indexArr(times){
      return times.time_start.reduce((acc,item,index) => {
        acc.push(index)
        return acc
      },[])
    },
    customEvent(_file, xhr,formData){
      xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
      );
      formData.append('image',_file);
      formData.append('lending_settings_id',this.landing.settings_id);
    },
    handleRemove(){
      this.addIntroBannerModel.image[this.landing.current_lang] = "";
    },
    handleSuccess(file){
      this.addIntroBannerModel.image[this.landing.current_lang] = file.xhr.response;
    },
    handleRemoveTopPro(){
      this.addIntroBannerModel.pro[this.landing.current_lang].top_img = "";
    },
    handleSuccessTopPro(file){
      this.addIntroBannerModel.pro[this.landing.current_lang].top_img = file.xhr.response;
    },
    handleRemoveTopLogo(){
      this.addIntroBannerModel.pro[this.landing.current_lang].logo_img= "";
    },
    handleSuccessTopLogo(file){
      this.addIntroBannerModel.pro[this.landing.current_lang].logo_img = file.xhr.response;
    },
    closeIntroAddBannerDialog(){
      this.$emit('closeIntroAddBannerDialog');
    },
    async addIntroBannerConfirm(){
      this.addIntroBannerModel.segment_ids = this.selectedSegments.reduce((acc,item) => {
        acc.push(item.id);
        return acc;
      },[]);
      if(this.type === 'create'){
        this.addIntroBannerModel.hotel_id = this.objectItem.id;
        await this.postIntroBanner(this.addIntroBannerModel);
      }else{
        await this.putIntroBanner(this.addIntroBannerModel);
      }
      this.$emit('addIntroBannerConfirm');
    },
  },
}
</script>

<style lang="scss">
.cropper-bg {
  background-repeat: repeat;
}
.cropper-controls {
  position: absolute;
  top: 10px;
  height: 36px;
  width: 36px;
  z-index: 9999;
  border-radius: 5px;
  background: #fff;
  border: 2px solid rgb(0, 125, 181);
  display: flex;
  justify-content: center;
  align-items: center;
}
.cropper-controls::before {
  background-color: #007aff;
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.confirm-control {
  font-family: Inter, sans-serif;
  background: #fff;
  border: 2px solid rgb(0, 125, 181);
  color: #007aff;
  border-radius: 5px;
}
.confirm-control::before {
  background-color: #007aff;
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.confirm-control:hover::before {
  opacity: 0.2;
}
.active-control::before {
  opacity: 0.2;
}
.color{
  cursor: pointer;
  height: 30px;
  width: 30px;
  border: 1px solid #000;
  border-radius: 4px;
}
.dropzone .dz-preview .dz-details .dz-size {
  display: none;
}
.dropzone .dz-preview .dz-details .dz-filename {
  display: none;
}
.dropzone {
   & .dz-preview {
     & .dz-image {
       & img {
         width: 150px;
         height: 150px;
         object-fit: contain;
       }
     }
     & .dz-error-message{
       width:200px;
       top:90px;
     }
     & .dz-remove{
       margin-left: 9px;
       padding: 5px 10px;
       bottom: 8px;
     }
   }
}
</style>
